import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ShareIcon from "@mui/icons-material/Share";
import { Link, useParams } from "react-router-dom";

import Styles from "./article.module.scss";
import weddingimg from "../../assets/image/wedding_img.jpg";
import trendimg1 from "../../assets/image/trend_img1.jpg";
import trendimg2 from "../../assets/image/trend_img2.jpg";
import trendimg3 from "../../assets/image/trend_img3.jpg";
import SecureDating from "./secure-dating";
import FunLovingPeople from "./fun-loving-people";
import AllArticles from '../../json/articles.json'
import EnhancingIntimacy from "./enhancing-intimacy";
import FirstPrivateDate from "./first-private-date";
import CoupleSecrets from "./couple-secrets";
import FindingPerfectMatch from "./finding-perfect-match";
import ImpactOfDivorceAndBreakup from "./impact-of-divorce-and-breakup";


function BlogDetails() {
  let { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const Images = {
    '1': trendimg1,
    '2': trendimg2,
    '3': trendimg3
  }

  const blogDetail = {
    'secure-dating': <SecureDating />,
    'fun-loving-people': <FunLovingPeople />,
    'enhancing-intimacy': <EnhancingIntimacy />,
    'first-private-date': <FirstPrivateDate />,
    'couple-secrets': <CoupleSecrets />,
    'finding-perfect-match': <FindingPerfectMatch />,
    'impact-of-divorce-and-breakup': <ImpactOfDivorceAndBreakup />
  }

  console.log(id);
  return (
    <>
      <Typography component="div" className={Styles.blog_details_sec}>
        <Typography component="div" className={Styles.blog_details_info}>
          <Container>
            <Typography component="div" className={Styles.blog_heading}>
              <Typography variant="h3">Prepare For Your Wedding Day</Typography>
            </Typography>
          </Container>
        </Typography>

        <Typography component="div" className={Styles.blog_details_content}>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Typography component="div" className={Styles.details_card}>
                  <Typography component="div" className={Styles.content_img}>
                    <img src={weddingimg} alt="wedding img" />
                    <Typography component="div" className={Styles.img_date}>
                      <p>23, july 2024</p>
                    </Typography>
                    <Typography component="div" className={Styles.share_icon}>
                      <ShareIcon />
                    </Typography>
                  </Typography>

                  <Typography
                    component="div"
                    className={Styles.article_section}
                  >
                    <Typography
                      component="div"
                      className={Styles.article_heading}
                    >
                      <span>Dating</span>
                      <span>Events</span>
                      <span>Decoration</span>
                    </Typography>

                    {blogDetail[id]}

                  </Typography>
                </Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography component="div" className={Styles.social_section}>
                  <Typography component="div" className={Styles.social_box}>
                    <Typography variant="h5">Social media</Typography>
                    <Typography
                      component="div"
                      className={Styles.social_content}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Box className={Styles.facbook_box}>
                            <Typography component="h6">
                              <span>3k</span> Facebook
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Box className={Styles.twitter_box}>
                            <Typography component="h6">
                              <span>10K</span> Twitter
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Box className={Styles.link_box}>
                            <Typography component="h6">
                              <span>1k</span> Linkedin
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Box className={Styles.youtube_box}>
                            <Typography component="h6">
                              <span>3k</span> Facebook
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Typography>
                  </Typography>

                  {/* <Typography component="div" className={Styles.category_box}>
                    <Typography variant="h5">CATEGORY</Typography>
                    <Typography
                      component="div"
                      className={Styles.category_content}
                    >
                      <Box className={Styles.planing_box}>
                        <Typography component="div">
                          <span>1</span> <b>Wedding Planning</b>
                        </Typography>
                      </Box>
                    </Typography>

                    <Typography
                      component="div"
                      className={Styles.category_content}
                    >
                      <Box className={Styles.planing_box}>
                        <Typography component="div">
                          <span>2</span> <b>Lifestyle</b>
                        </Typography>
                      </Box>
                    </Typography>

                    <Typography
                      component="div"
                      className={Styles.category_content}
                    >
                      <Box className={Styles.planing_box}>
                        <Typography component="div">
                          <span>3</span> <b>Catering services</b>
                        </Typography>
                      </Box>
                    </Typography>

                    <Typography
                      component="div"
                      className={Styles.category_content}
                    >
                      <Box className={Styles.planing_box}>
                        <Typography component="div">
                          <span>4</span> <b>Wedding Decorations</b>
                        </Typography>
                      </Box>
                    </Typography>

                    <Typography
                      component="div"
                      className={Styles.category_content}
                    >
                      <Box className={Styles.planing_box}>
                        <Typography component="div">
                          <span>5</span> <b>Wedding Halls</b>
                        </Typography>
                      </Box>
                    </Typography>

                    <Typography
                      component="div"
                      className={Styles.category_content}
                    >
                      <Box className={Styles.planing_box}>
                        <Typography component="div">
                          <span>6</span> <b>The Ceremony</b>
                        </Typography>
                      </Box>
                    </Typography>

                    <Typography
                      component="div"
                      className={Styles.category_content}
                    >
                      <Box className={Styles.planing_box}>
                        <Typography component="div">
                          <span>7</span> <b>Photography </b>
                        </Typography>
                      </Box>
                    </Typography>
                  </Typography> */}

                  {/* Trending Post */}

                  <Typography component="div" className={Styles.trending_box}>
                    <Typography variant="h5">TRENDING POSTS</Typography>

                    {AllArticles.articles.filter(item => item.id !== id).slice(0, 5).map(item => (
                      <Typography
                        component="div"
                        className={Styles.trending_content}
                        key={item.id}
                      >
                        <Link className={Styles.trending_box_post} to={`/article/${item.id}`}>
                          <Typography component="div">
                            <img src={Images[item.image]} alt="trend img" />
                          </Typography>
                          <Typography
                            component="p"
                            className={Styles.post_content}
                          >
                            {item.title}
                          </Typography>
                        </Link>
                      </Typography>
                    ))}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Typography>
      </Typography>
    </>
  );
}
export default BlogDetails;
