import React from 'react';
import BannerSection from "../../components/banner_section.js";
import Blog from './blog.js';
import MatchingSection from '../../components/matching_section.js';



export const Homepage = () =>{
  return (
    <>
      <BannerSection/>
      <Blog/>
      <MatchingSection />
    </>
  );
}

export default Homepage;
