import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import UserRoutes from './Routes/routes.js';
import './App.css';

function App() {

  return (
    <>
      <BrowserRouter>
        <UserRoutes />
      </BrowserRouter>
    </>
  );
}

export default App;
