import * as React from "react";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";

function BannerSection() {


  return (
    <>
      <Container>
        <Typography component="div">
          <Typography component="div" sx={{ backgroundColor: "#fffcf2", height: "500px" }}>
            <iframe title="kiwiirc hybridirc" src="https://kiwiirc.hybridirc.com/#lobby" allow="camera; microphone; display-capture; fullscreen" style={{ border: 0, width: '100%', height: '500px' }} />
          </Typography>
        </Typography>
      </Container>
    </>
  );
}
export default BannerSection;
