import * as React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Styles from "./matching_section.module.scss";

function Furniture() {
  return (
    <>
      <Typography component="div" className={Styles.match_sec}>
        <Container>
          <Typography component="div" className={Styles.match_info}>
            <Typography component="h2">Find your perfect Match now</Typography>
            <Typography component="div" className={Styles.match_info_p}>
              lacinia viverra lectus. Fusce imperdiet ullamcorper metus eu
              fringilla.Lorem Ipsum is simply dummy text of the printing and
              typesetting industry.
            </Typography>

            <Typography component="div" className={Styles.match_btn}>
              <Typography component="div">
                <Link href="#" className={Styles.link_btn1}>
                  Register Now
                </Link>
              </Typography>

              <Typography component="div">
                <Link href="#" className={Styles.link_btn2}>
                  Help & Support
                </Link>
              </Typography>
            </Typography>
          </Typography>
        </Container>
      </Typography>
    </>
  );
}
export default Furniture;
