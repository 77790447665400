import * as React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Styles from "./faq.module.scss";
import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
// import Grid from "@mui/material/Grid";
// import { styled } from "@mui/material/styles";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
// }));

function Faq() {
  return (
    <>
      <Typography component="div" className={Styles.faq_card}>
        <Container>
          <Typography component="div" className={Styles.faq_card}>
            <Typography component="div" className={Styles.faq_head}>
              <Typography component="p">FAQ</Typography>
              <Typography variant="h4">Frequently asked questions</Typography>
            </Typography>

            <Box className={Styles.faq_accordion_sec}>
              <Accordion
                defaultExpanded
                sx={{ marginBottom: "20px", boxShadow: "unset" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography className={Styles.accor_title}>
                    1. What strategies can I use to gauge the sincerity and
                    intentions of the people I'm interested in?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={Styles.accor_sub_title}>
                    To gauge sincerity and intentions, observe consistency in
                    their words and actions over time. Pay attention to how they
                    communicate about future plans and commitments. Engage in
                    meaningful conversations about values, goals, and past
                    relationships to understand their mindset. Trust your
                    intuition and observe how they treat others in their life.
                    These steps can help reveal whether someone is genuine and
                    aligned with your expectations.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion sx={{ marginBottom: "20px", boxShadow: "unset" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography className={Styles.accor_title}>
                    2. What support systems or resources are available to me if
                    I encounter challenges or need guidance while dating online?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={Styles.accor_sub_title}>
                    When dating online, you can rely on several support systems
                    and resources for guidance and assistance. Most dating
                    platforms like ExcelMatch and Women Driven offer customer
                    support to help with technical issues and safety concerns.
                    Additionally, online forums and communities like Reddit or
                    specialized dating advice websites like Women Driven provide
                    a wealth of user-generated tips and experiences. Seeking
                    guidance from friends or family who have experience in
                    online dating can also be valuable.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion sx={{ marginBottom: "20px", boxShadow: "unset" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                >
                  <Typography className={Styles.accor_title}>
                    3. How can I verify the authenticity of the profiles I'm
                    interested in before initiating contact?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={Styles.accor_sub_title}>
                    To verify the authenticity of profiles before reaching out,
                    start by checking for consistency in profile details like
                    photos, bios, and interests across different platforms.
                    Reverse image search their profile pictures to see if they
                    appear elsewhere online. Look for social media links or
                    mutual connections to confirm their identity further. If
                    you're still unsure, ask for a video call or meeting in a
                    public place early on to gauge authenticity and ensure they
                    match their online persona. These steps can help you
                    navigate online dating safely.
                    <p>OR</p>
                    The reputation of the dating platform and its policies for
                    verifying members can provide valuable clues about the
                    authenticity of profiles. Platforms with robust verification
                    processes and a good reputation are likely to have more
                    genuine profiles. Checking for user reviews and
                    understanding how the platform handles profile authenticity
                    can help you make informed decisions before initiating
                    contact with someone online.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion sx={{ marginBottom: "20px", boxShadow: "unset" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4-content"
                  id="panel4-header"
                >
                  <Typography className={Styles.accor_title}>
                    4. What steps can I take to ensure my online interactions
                    remain respectful and positive for both parties?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={Styles.accor_sub_title}>
                    To ensure respectful and positive online interactions,
                    always communicate with kindness and empathy. Respect
                    personal boundaries and consent at all times. Avoid making
                    assumptions or judgments based on limited information. Use
                    clear and honest communication, and be open to feedback.
                    Finally, report any behavior that crosses boundaries or
                    feels inappropriate to the platform's moderation team.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion sx={{ marginBottom: "20px", boxShadow: "unset" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5-content"
                  id="panel5-header"
                >
                  <Typography className={Styles.accor_title}>
                    5. What should I expect and do before a private meeting?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={Styles.accor_sub_title}>
                    Before a private meeting, choose an outfit that highlights
                    your best features and attracts your partner's attention.
                    Prioritize safety by ensuring you have contraception like
                    condoms to prevent unplanned pregnancy and protect against
                    sexually transmitted infections. Fulfill your desires
                    responsibly by discussing boundaries and expectations
                    beforehand, ensuring a positive and safe experience for both
                    parties involved.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion sx={{ marginBottom: "20px", boxShadow: "unset" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel6-content"
                  id="panel6-header"
                >
                  <Typography className={Styles.accor_title}>
                    6. How can I honor the privacy of my date?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={Styles.accor_sub_title}>
                    Respecting your date's privacy is essential for fostering
                    trust and mutual respect. Always ask for permission before
                    sharing any personal information they disclose to you.
                    Respect their boundaries regarding sharing photos, messages,
                    or details about your interactions with others. Use secure
                    communication channels to protect their privacy, especially
                    when discussing sensitive topics. Avoid posting about them
                    on social media or sharing details of your relationship
                    without their explicit consent. Openly discuss privacy
                    concerns and respect their preferences regarding privacy
                    settings on platforms you both use. These steps help
                    maintain a respectful and trusting relationship.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion sx={{ marginBottom: "20px", boxShadow: "unset" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel7-content"
                  id="panel7-header"
                >
                  <Typography className={Styles.accor_title}>
                    7. How can I discover and address impotency concerns?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={Styles.accor_sub_title}>
                    Discovering and addressing impotency concerns involves open
                    communication and seeking medical advice if needed. Start by
                    discussing any changes or difficulties with arousal or
                    performance with your partner in a supportive and
                    understanding manner. Consider lifestyle factors such as
                    stress, diet, and exercise that may affect sexual health. If
                    concerns persist, consult a healthcare professional for a
                    thorough evaluation and potential treatment options.
                    Addressing these concerns early can lead to effective
                    solutions and improve intimacy in your relationship.
                    MEDICINE LIKE VIAGRA OR AYURVEDIC MEDICINES.
                    <p>
                      Medications like Viagra (sildenafil) and certain Ayurvedic
                      medicines can help address erectile dysfunction (ED),
                      which is a common form of male impotency. Here’s how each
                      type of medication works:
                    </p>
                    <ol>
                      <li>
                        Viagra and Similar Medications (Phosphodiesterase-5
                        Inhibitors):
                        <ul>
                          <li>
                            Mechanism: Viagra and other phosphodiesterase-5
                            (PDE-5) inhibitors work by enhancing the effects of
                            nitric oxide, a chemical that relaxes muscles in the
                            penis. This relaxation allows increased blood flow
                            into the penis, which is necessary for achieving and
                            maintaining an erection.
                          </li>
                          <li>
                            Usage: These medications are typically taken orally,
                            about 30 minutes to an hour before sexual activity.
                            They are effective for many men with ED and can help
                            improve erectile function temporarily.
                          </li>
                        </ul>
                      </li>

                      <li>
                        Ayurvedic Medicines:
                        <ul>
                          <li>
                            Mechanism: Ayurvedic treatments for erectile
                            dysfunction often involve herbal remedies that aim
                            to improve overall sexual health and vitality. These
                            medicines may work through various mechanisms such
                            as improving blood flow, enhancing hormonal balance,
                            reducing stress, and increasing libido.
                          </li>
                          <li>
                            Ingredients: Ayurvedic medicines may contain herbs
                            like ashwagandha, shilajit, ginseng, gokshura, and
                            others known for their adaptogenic and aphrodisiac
                            properties.
                          </li>
                          <li>
                            Usage: They are typically taken orally as capsules,
                            powders, or decoctions, following specific dosage
                            guidelines provided by Ayurvedic practitioners.
                          </li>
                        </ul>
                      </li>
                    </ol>
                    <p>
                      Both Viagra and Ayurvedic medicines can be effective in
                      addressing impotency by improving erectile function.
                      However, it’s important to consider the following:
                    </p>
                    <ul>
                      <li>
                        Consultation: Before starting any medication, it’s
                        essential to consult with a healthcare provider or
                        Ayurvedic practitioner. They can evaluate your specific
                        condition, medical history, and any medications you are
                        currently taking to ensure safety and effectiveness.
                      </li>
                      <li>
                        Side Effects: Both types of medications may have side
                        effects. Common side effects of Viagra include headache,
                        flushing, indigestion, and nasal congestion. Ayurvedic
                        medicines, when not used properly, may also cause
                        adverse effects or interact with other medications.
                      </li>
                      <li>
                        Underlying Causes: While these medications can treat the
                        symptoms of ED, they do not address the underlying
                        causes, which may include cardiovascular disease,
                        diabetes, hormonal imbalances, or psychological factors.
                        Addressing these underlying causes is crucial for
                        long-term management of ED.
                      </li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion sx={{ marginBottom: "20px", boxShadow: "unset" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel8-content"
                  id="panel8-header"
                >
                  <Typography className={Styles.accor_title}>
                    8. Where is a safe place to meet someone for the first time?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={Styles.accor_sub_title}>
                    A safe place to meet someone for the first time is a public
                    location where there are other people around, such as a
                    coffee shop, restaurant, or park during the daytime. Choose
                    a place that is familiar to you and easily accessible. Avoid
                    secluded areas or private residences until you feel
                    comfortable and trust the person. Meeting in a public
                    setting ensures both safety and comfort for an initial
                    encounter.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion sx={{ marginBottom: "20px", boxShadow: "unset" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel9-content"
                  id="panel9-header"
                >
                  <Typography className={Styles.accor_title}>
                    9. Is it better to meet one person exclusively or explore
                    multiple matches?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={Styles.accor_sub_title}>
                    <p>
                      Its not marriage or dating. Website 2 is for fun and gain
                      what is missing in one’s life. (if married then without
                      breaking marriage). No long term commitment needed. But if
                      they like then they can meeting frequently and/or long
                      terms. Mostly they meet to cool down.
                    </p>

                    <p>
                      Exploring multiple matches offers several advantages: it
                      allows you to experience a variety of personalities,
                      preferences, and experiences, broadening your
                      understanding of what you find fulfilling. This approach
                      provides flexibility, increasing your chances of finding
                      someone who aligns closely with your desires and
                      interests. Moreover, there's less immediate pressure to
                      commit deeply or invest fully in one person, allowing
                      relationships to develop naturally and comfortably over
                      time. It fosters an environment where you can explore and
                      discover what truly resonates with you without feeling
                      constrained by early commitments.
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion sx={{ marginBottom: "20px", boxShadow: "unset" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel10-content"
                  id="panel10-header"
                >
                  <Typography className={Styles.accor_title}>
                    10. Who should meet for a private date and why?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={Styles.accor_sub_title}>
                    <p>
                      A private date is typically best suited for individuals
                      who have already established a level of comfort and trust
                      through initial interactions. It allows both parties to
                      engage more intimately and authentically without external
                      distractions, fostering deeper conversation and
                      connection. Private dates are ideal for those seeking to
                      build a more personal relationship and explore
                      compatibility in a more focused environment. This setting
                      encourages openness and vulnerability, facilitating a
                      genuine exchange that can potentially lead to a deeper
                      bond. ITS FOR HOOKUP ONLY. SO SEXUALY UNSATISFIED MAN AND
                      WOMAN CAN MEET OR PEOPLE WHO HAVE FETISH.
                    </p>

                    <p>
                      A private date is typically best suited for individuals
                      who are
                    </p>

                    <ol>
                      <li>
                        Sexually Unsatisfied Individuals: Those who are looking
                        to fulfill their sexual needs or explore new experiences
                        outside of their current relationships or circumstances.
                      </li>
                      <li>
                        People with Specific Fetishes: Individuals who have
                        specific sexual interests or fetishes that they wish to
                        explore with a consenting partner who shares or is open
                        to those preferences.
                      </li>
                    </ol>
                    <p>
                      The reason for meeting is to provide a safe and consensual
                      environment where both parties can openly communicate
                      their desires and boundaries. This allows them to engage
                      in activities that fulfill their sexual fantasies or
                      satisfy their unmet needs without the expectations of a
                      long-term commitment or emotional attachment.
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion sx={{ marginBottom: "20px", boxShadow: "unset" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel11-content"
                  id="panel11-header"
                >
                  <Typography className={Styles.accor_title}>
                    11. How do I know when it's time to move on?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={Styles.accor_sub_title}>
                    <p>
                      Knowing when it's time to move on often involves
                      recognizing signs like stagnation, unhappiness, or a toxic
                      environment. Feeling consistently unmotivated,
                      experiencing burnout, or sensing a misalignment with
                      personal values can also signal the need for change.
                      Trusting your intuition and considering both immediate
                      dissatisfaction and long-term goals are crucial in making
                      this decision. Seeking guidance from mentors or trusted
                      individuals can offer clarity and support during this
                      process.
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion sx={{ marginBottom: "20px", boxShadow: "unset" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel12-content"
                  id="panel12-header"
                >
                  <Typography className={Styles.accor_title}>
                    12. What is an acceptable age difference in hookup?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={Styles.accor_sub_title}>
                    <p>
                      In the context of hookups, age differences can vary widely
                      based on personal preferences and cultural norms.
                      Generally, what is considered acceptable often depends on
                      legal considerations (meeting age of consent
                      requirements), mutual consent, and comfort levels between
                      individuals involved. As long as all parties are
                      consenting adults and there is mutual respect, the age
                      difference may be less of a concern in casual hookups
                      compared to more serious relationships where long-term
                      compatibility and life stages become more significant
                      factors.{" "}
                      <b>
                        NO BAR. AGE 20 CAN MEET AGE 40. YOU MIGHT HAVE OBSERVED
                        THAT SOME YOUNG MEN MEET OLD WOMAN (COMMON AND VISIBLE),
                        OTHERWISE ROUND ALSO HAPPENED BUT NOT VISIBLE.
                      </b>
                      In the realm of hookups, the acceptability of age
                      differences is largely subjective. As long as all
                      participants are legal adults and consenting, the decision
                      rests on personal comfort and mutual respect. Some may
                      embrace significant age gaps, while others prefer closer
                      age proximity.
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion sx={{ marginBottom: "20px", boxShadow: "unset" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel13-content"
                  id="panel13-header"
                >
                  <Typography className={Styles.accor_title}>
                    13. How do I know if someone is a compatible partner?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={Styles.accor_sub_title}>
                    <p>
                      Determining compatibility involves assessing shared
                      values, effective communication, mutual respect, emotional
                      connection, and shared interests. It's crucial to feel
                      understood and supported by your partner, navigate
                      differences constructively, and envision a compatible
                      future together. Trust and security form the foundation
                      for a healthy relationship, ensuring mutual growth and
                      happiness.
                    </p>
                    <br></br>
                    <p>SAME LEVEL OF LIBIDO</p>
                    <p>
                      When considering partners with the same level of libido in
                      a hookup context, it can enhance the compatibility and
                      satisfaction for both individuals. Matching libidos
                      generally lead to a more harmonious sexual experience,
                      where desires and expectations are aligned. This mutual
                      understanding can reduce potential conflicts and ensure
                      that both parties enjoy a fulfilling encounter without
                      concerns about mismatched sexual appetites. Communication
                      remains key to maintaining this harmony and ensuring
                      ongoing satisfaction.
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion sx={{ marginBottom: "20px", boxShadow: "unset" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel14-content"
                  id="panel14-header"
                >
                  <Typography className={Styles.accor_title}>
                    14. What if romantic feelings develop during a private
                    meeting?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={Styles.accor_sub_title}>
                    <p>
                      If romantic feelings develop during a private meeting,
                      it's important to approach the situation with sensitivity
                      and respect for both yourself and the other person. Take
                      time to reflect on your feelings and consider the context
                      of the meeting and your existing relationship, if any.
                      Communicate openly and honestly with the other person
                      about your feelings, ensuring mutual consent and
                      understanding. Proceeding with caution and respect for
                      boundaries is crucial to navigating new romantic
                      developments in a healthy and mutually respectful manner.
                      <b>
                        THEY SHOULD NOT FALL IN FEELINGS AS IT IS FOR PHYSICAL
                        FUN ONLY. IF THEY DO FALL IN FEELING THEN THEY SHOULD
                        KNOW THE BOUNDARIES AND REPERCUSSION.
                      </b>
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion sx={{ marginBottom: "20px", boxShadow: "unset" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel15-content"
                  id="panel15-header"
                >
                  <Typography className={Styles.accor_title}>
                    15. How can I protect myself while meeting someone
                    privately?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={Styles.accor_sub_title}>
                    <p>
                      To protect yourself during a private meeting, choose a
                      public venue for the initial encounter and inform a
                      trusted friend or family member of your plans. Trust your
                      intuition and have a clear exit strategy in case you feel
                      uncomfortable. Avoid sharing personal details too soon and
                      stay sober to make informed decisions. Setting boundaries
                      and using communication tools responsibly can also help
                      ensure your safety and well-being.{" "}
                      <b>
                        MAN SHOULD BRING CONDOM. WOMAN SHOULD ALSO BRING OF
                        VARIOUS SIZES. NO FULLY UNDRESS DURING FIRST MEETING.
                        KEEP IT SHORT. TELL WHAT IS ACCEPTABLE. DO NOT ALLOW
                        WHAT YOU ARE NOT COMFORTABLE IN.
                      </b>
                    </p>
                    <p>
                      Both parties should bring condoms; women should bring
                      various sizes. During the first meeting, avoid full
                      undressing and keep interactions brief. Only engage in
                      activities that both parties are comfortable with and
                      agree upon. This ensures a respectful and safe experience
                      for everyone involved.
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion sx={{ marginBottom: "20px", boxShadow: "unset" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel16-content"
                  id="panel16-header"
                >
                  <Typography className={Styles.accor_title}>
                    16. When is it appropriate to engage in unprotected
                    activities during a private meeting?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={Styles.accor_sub_title}>
                    <p>
                      Engaging in unprotected activities during a private
                      meeting should only occur when there is mutual trust,
                      commitment, and clear communication about sexual health
                      and contraception. Both parties should be aware of each
                      other's STI status and have agreed upon a method for
                      preventing pregnancy and STIs. Consent must be freely
                      given and enthusiastic throughout. Prioritizing safety and
                      ensuring both partners feel comfortable and informed is
                      essential for a respectful and healthy intimate encounter.
                      AFTER SOME MEETINGS WHEN YOU FEEL COMFORTABLE AND
                      CONFIDENT THEN SOME UNPROTECTED OK
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion sx={{ marginBottom: "20px", boxShadow: "unset" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel17-content"
                  id="panel17-header"
                >
                  <Typography className={Styles.accor_title}>
                    17. Can you provide information on alternate lifestyles for
                    dating?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={Styles.accor_sub_title}>
                    <p>
                      Alternate lifestyles for dating include polyamory
                      (multiple consensual relationships), open relationships
                      (allowing partners to explore outside connections),
                      swinging (couples engaging in consensual group
                      activities), Threesome A threesome involves three people
                      engaging in sexual activity together. It can be a couple
                      inviting a third person into their relationship or three
                      individuals exploring mutual attraction) and Cuckolding is
                      a fetish or kink where a person (often a man) derives
                      pleasure from their partner engaging in sexual activities
                      with someone else.These lifestyles emphasize
                      communication, consent, and mutual respect, fostering
                      diverse relationship dynamics beyond traditional norms.
                      Understanding and respecting boundaries and preferences
                      are crucial for maintaining healthy and fulfilling
                      relationships in these communities.{" "}
                      <b>NO BDSM. WRITE ABOUT 3 SOME AND CUCKOLDS ALSO</b>
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion sx={{ marginBottom: "20px", boxShadow: "unset" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel18-content"
                  id="panel18-header"
                >
                  <Typography className={Styles.accor_title}>
                    18. What to do if my spouse or other family members discover
                    my relationship?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={Styles.accor_sub_title}>
                    <p>
                      If your spouse or family members discover your
                      relationship outside of your primary partnership, approach
                      the situation with honesty and empathy. Have an open
                      conversation to address their concerns and feelings,
                      listening actively without defensiveness. Take
                      responsibility for your actions, discuss reasons behind
                      the relationship, and consider seeking counseling if
                      needed to facilitate understanding and healing. Respecting
                      boundaries and rebuilding trust through consistent effort
                      is crucial in navigating this challenging situation.
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion sx={{ marginBottom: "20px", boxShadow: "unset" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel19-content"
                  id="panel19-header"
                >
                  <Typography className={Styles.accor_title}>
                    19. How to find a compatible partner?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={Styles.accor_sub_title}>
                    <p>
                      Finding a compatible partner involves self-reflection to
                      understand your values and goals. Engage in activities
                      that align with your interests to meet like-minded
                      individuals. Practice open communication to discuss
                      expectations and boundaries early on. Look for shared
                      values, mutual respect, and emotional compatibility as key
                      indicators of a potential long-term connection.{" "}
                      <b>IT IS RELATED WITH SEXUAL COMPATIBILITIES ONLY</b>
                    </p>
                    <p>
                      Finding a compatible partner involves understanding your
                      own libido, timing, and desires while seeking someone who
                      shares similar values and interests. Start by exploring
                      potential partners through mutual activities, hobbies, or
                      shared social circles to gauge compatibility.
                      Communication about expectations regarding intimacy,
                      timing, and mutual desires is crucial to ensure alignment.
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion sx={{ marginBottom: "20px", boxShadow: "unset" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel20-content"
                  id="panel20-header"
                >
                  <Typography className={Styles.accor_title}>
                    20. What if your spouse is impotent? How to fulfil desire?
                    Cure?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={Styles.accor_sub_title}>
                    <p>
                      If your spouse is experiencing impotence, approach the
                      situation with open communication and empathy. Encourage
                      them to seek medical advice from a healthcare professional
                      to explore treatment options. Focus on non-physical forms
                      of intimacy and emotional connection to maintain
                      closeness. Educate yourself about potential treatments and
                      support your spouse throughout the process with patience
                      and understanding.
                    </p>
                    <p>
                      If you're considering a physical relationship with someone
                      else, it's essential to prioritize open and honest
                      communication with your spouse. Discuss your desires and
                      concerns openly, respecting their feelings and seeking
                      their opinion. Transparency and mutual understanding are
                      crucial in navigating such sensitive matters within a
                      relationship.
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Typography>
        </Container>
      </Typography>
    </>
  );
}
export default Faq;
