import * as React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Styles from "./footer.module.scss";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Link } from "@mui/material";
import Icon1 from "../assets/image/icon1.png";
import Icon2 from "../assets/image/icon2.png";
import Icon3 from "../assets/image/icon3.png";
import Icon4 from "../assets/image/icon4.png";

function Furniture() {
  return (
    <>
      <Typography component="div" className={Styles.footer_sec}>
        <Container>
          <Box className={Styles.footer_info_box}>
            <Typography variant="h5" className={Styles.info_box_title}>
              Free support:
              <span className={Styles.fontstyle}> +92 (8800) 68 - 8960 </span> <span className={Styles.space}>|</span>
              Email: <span className={Styles.fontemail}>info@example.com </span>
            </Typography>
          </Box>

          <Typography component="div" className={Styles.foot_menu_sec}>
            <Grid container spacing={2}>
              <Grid item md={4} xs={12}>
                <Typography component="div" className={Styles.foot_menu_info}>
                  <Typography component="div" className={Styles.menu_title}>
                    <Typography variant="h5">Get In Touch</Typography>
                  </Typography>
                  <Box>
                    <p>Address: 3812 Lena Lane City Jackson Mississippi</p>
                  </Box>
                  <Box>
                    <p>Phone: +92 (8800) 68 - 8960</p>
                  </Box>

                  <Box>
                    <p>Email: info@example.com</p>
                  </Box>
                </Typography>
              </Grid>

              <Grid item md={4} xs={12}>
                <Typography component="div" className={Styles.foot_menu_info}>
                  <Typography component="div" className={Styles.menu_title}>
                    <Typography variant="h5">HELP & SUPPORT</Typography>
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <Box className={Styles.foot_link_menu}>
                        <Link href="#" className={Styles.foot_link_txt}>
                          About company
                        </Link>
                      </Box>

                      <Box className={Styles.foot_link_menu}>
                        <Link href="#" className={Styles.foot_link_txt}>
                          Feedback
                        </Link>
                      </Box>

                      <Box className={Styles.foot_link_menu}>
                        <Link href="#" className={Styles.foot_link_txt}>
                          Testimonials
                        </Link>
                      </Box>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Box className={Styles.foot_link_menu}>
                        <Link href="#" className={Styles.foot_link_txt}>
                          Contact us
                        </Link>
                      </Box>

                      <Box className={Styles.foot_link_menu}>
                        <Link href="#" className={Styles.foot_link_txt}>
                          FAQs
                        </Link>
                      </Box>
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>

              <Grid item md={4} xs={12}>
                <Typography component="div" className={Styles.social_menu_info}>
                  <Typography component="div" className={Styles.menu_title}>
                    <Typography variant="h5">SOCIAL MEDIA</Typography>
                  </Typography>
                  <Box className={Styles.social_menu}>
                    <Link href="#">
                      <img src={Icon1} alt="icon"></img>
                    </Link>
                    <Link href="#">
                      <img src={Icon2} alt="icon"></img>
                    </Link>
                    <Link href="#">
                      <img src={Icon3} alt="icon"></img>
                    </Link>
                    <Link href="#">
                      <img src={Icon4} alt="icon"></img>
                    </Link>
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </Typography>

          <Typography component="div" className={Styles.foot_note_sec}>
            <Box>
              <p>
                Company name Site - Trusted by over thousands of Boys & Girls
                for successfull marriage.
              </p>
            </Box>
            <Box>
              <Link href="#" className={Styles.join_btn}>
                Join us today !
              </Link>
            </Box>
          </Typography>
        </Container>
        {/* copyrights section */}
        <Typography component="div" className={Styles.copyright_sec}>
          <Container>
            <Typography component="p" className={Styles.copyright_box}>
              Copyright © 2024
              <Link href="#" sx={{ fontSize: "14px", color: "#415764", marginLeft: "5px", marginRight: "5px", textDecorationColor: "inherit" }}>
                Company.com
              </Link>
              All rights reserved.
            </Typography>
          </Container>
        </Typography>
      </Typography>
    </>
  );
}
export default Furniture;
