import * as React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';

import Img1 from "../../assets/image/img1.jpg";
import Img2 from "../../assets/image/img2.jpg";
import Img3 from "../../assets/image/img3.jpg";
import Styles from "./homepage.module.scss";
import AllArticles from '../../json/articles.json'
import { Link } from "react-router-dom";

function Furniture() {

  const Images = {
    '1': Img1,
    '2': Img2,
    '3': Img3
  }

  return (
    <>
      <Typography component="div" className={Styles.blog_sec}>
        <Container>
          <Typography component="div" className={Styles.blog_info}>
            <Typography component="h1">BLOG POSTS</Typography>
            <Typography component="h2">Blog & Articles</Typography>
            <span className={Styles.title_img}></span>
          </Typography>

          <Typography component="div" className={Styles.our_box}>
            <Box>
              <Carousel
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="container-with-dots"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024
                    },
                    items: 3,
                    partialVisibilityGutter: 40
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0
                    },
                    items: 1,
                    partialVisibilityGutter: 30
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464
                    },
                    items: 2,
                    partialVisibilityGutter: 30
                  }
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
              >
                {AllArticles.articles.map(item => (
                  <Typography component="div" className={Styles.box}>

                    <Typography component="div" className={Styles.img_box}>
                      <img src={Images[item.image]} width="100%" alt="imag" />
                    </Typography>

                    <Typography component="div" className={Styles.detail_box}>
                      <Typography variant="h4">{item.title}</Typography>
                      <Typography variant="p">{item.description}</Typography>
                    </Typography>

                    <Typography component="div" className={Styles.blog_box_btn}>
                      <Link to={`/article/${item.id}`} className={Styles.link_txt}>
                        Read more
                      </Link>
                    </Typography>

                  </Typography>
                ))}
              </Carousel>
            </Box>
          </Typography>
        </Container>
      </Typography>
    </>
  );
}
export default Furniture;
