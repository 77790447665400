import React from 'react'
import { Route, Routes } from 'react-router-dom'

import HomeLayout from '../layout/HomeLayout'
import Article from '../Pages/ArticleDetails/article.js'
import Homepage from '../Pages/Homepage/homepage'
import Faq from '../Pages/FAQ/FAQ'

const UserRoutes = () => {
    return (
        <Routes>
            <Route element={<HomeLayout />}>
                <Route path="/" element={<Homepage />} />
                <Route path="/article/:id" element={<Article />} />
                <Route path="/faq" element={<Faq />} />
            </Route>
            <Route path="*" element={<> Not Found </>} />
        </Routes>
    )
}

export default UserRoutes