import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import Link from "@mui/material/Link";
import CallIcon from "@mui/icons-material/Call";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";

import Styles from "./navbar.module.scss";
import Logo from "../assets/image/logo.png";
import AllArticle from "../json/articles.json";

const pages = ["Home", " Articles", "FaQ"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();

  const [anchorE4, setAnchorE4] = React.useState(null);

  const open4 = Boolean(anchorE4);

  const navigateTo = (path) => navigate(path);

  const handleClose = (path) => {
    navigateTo(path);
    setAnchorE4(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <Typography component="div" className={Styles.head_section}>
        <Container>
          <Typography component="div" className={Styles.head_flex}>
            <Typography component="div" className={Styles.head_menu}>
              <Box className={Styles.search_icon}>
                <SearchIcon className={Styles.search} />
              </Box>
              <Box>
                <Link href="#" className={Styles.head_link}>
                  About
                </Link>
              </Box>
              <Box>
                <Link href="#" className={Styles.head_link}>
                  FAQ
                </Link>
              </Box>
              <Box>
                <Link
                  href="#"
                  className={Styles.head_link}
                  sx={{ borderRight: "0 !important" }}
                >
                  Contact
                </Link>
              </Box>
            </Typography>

            <Typography component="div" className={Styles.head_menu}>
              <Box className={Styles.detail_icon}>
                <Typography component="div" className={Styles.small_icon}>
                  <CallIcon className={Styles.call_icon} />
                </Typography>
                <Typography variant="p">+01 5312 5312</Typography>
              </Box>
              <Box className={Styles.detail_icon}>
                <Typography component="div" className={Styles.small_icon}>
                  <MailOutlineIcon className={Styles.mail_icon} />
                </Typography>
                <Typography variant="p">help@company.com</Typography>
              </Box>

              <Box className={Styles.detail_icon}>
                <Typography component="div" className={Styles.small_icon}>
                  <FacebookIcon className={Styles.fb_icon} />
                </Typography>
              </Box>

              <Box className={Styles.detail_icon}>
                <Typography component="div" className={Styles.small_icon}>
                  <TwitterIcon className={Styles.twit_icon} />
                </Typography>
              </Box>

              <Box className={Styles.detail_icon}>
                <Typography component="div" className={Styles.small_icon}>
                  <WhatsAppIcon className={Styles.whats_icon} />
                </Typography>
              </Box>
            </Typography>
          </Typography>
        </Container>
      </Typography>
      <Typography component="div" className={Styles.navbar_sec}>
        <AppBar
          position="static"
          sx={{ backgroundColor: "#fff", height: "auto", boxShadow: "none" }}
        >
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  mr: 2,
                  display: { xs: "none", md: "flex" },
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                <Typography component="div" className={Styles.head_logo}>
                  <img src={Logo} alt="logo"></img>
                </Typography>
              </Typography>

              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="secondary"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {pages.map((page) => (
                    <MenuItem key={page} onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">{page}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              <Typography
                variant="h5"
                noWrap
                component="a"
                href="#app-bar-with-responsive-menu"
                sx={{
                  mr: 2,
                  display: { xs: "flex", md: "none" },
                  flexGrow: 1,
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "#000",
                  textDecoration: "none",
                }}
              >
                <Typography component="div" className={Styles.head_logo}>
                  <img src={Logo} alt="logo"></img>
                </Typography>
              </Typography>

              <Box
                className={Styles.menu_list}
                sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
              >
                <Typography component="div" className={Styles.menu_bar}>
                  <Typography>
                    <Button
                      className={Styles.menu_bar_link}
                      onClick={() => navigateTo("/")}
                    >
                      Home
                    </Button>
                  </Typography>

                  <Typography>
                    <Button
                      id="basic-button3"
                      aria-controls={open4 ? "basic-menu3" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open4 ? "true" : undefined}
                      onClick={(e) => setAnchorE4(e.currentTarget)}
                      className={Styles.menu_bar_link}
                    >
                      Articles <KeyboardArrowDownIcon />
                    </Button>
                    <Menu
                      id="basic-menu3"
                      anchorEl={anchorE4}
                      open={open4}
                      onClose={() => setAnchorE4(null)}
                      MenuListProps={{
                        "aria-labelledby": "basic-button3",
                      }}
                    >
                      {/* <MenuItem onClick={() => handleClose(`/article`)}>
                        <Box>
                          <Grid container spacing={2}>
                            <Grid item lg={6} xs={12}>
                              <Typography
                                component="div"
                                sx={{
                                  color: "#66451c",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  lineHeight: "30px",
                                }}
                              >
                                Secure Dating: 10 Vital Safety Tips for a
                                Risk-Free Experience
                              </Typography>
                            </Grid>
                            <Grid item lg={6} xs={12}>
                              <Typography
                                component="div"
                                sx={{
                                  color: "#66451c",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  lineHeight: "30px",
                                }}
                              >
                                Top 5 Alternate Lifestyles for Fun-Loving People
                              </Typography>
                            </Grid>
                            <Grid item lg={6} xs={12}>
                              <Typography
                                component="div"
                                sx={{
                                  color: "#66451c",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  lineHeight: "30px",
                                }}
                              >
                                Enhancing Intimacy: Navigating Medical
                                Challenges, Body Hygiene, and Impotency
                              </Typography>
                            </Grid>
                            <Grid item lg={6} xs={12}>
                              <Typography
                                component="div"
                                sx={{
                                  color: "#66451c",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  lineHeight: "30px",
                                }}
                              >
                                Top 4 Ways to have Best Sex on First Private
                                Date
                              </Typography>
                            </Grid>
                            <Grid item lg={6} xs={12}>
                              <Typography
                                component="div"
                                sx={{
                                  color: "#66451c",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  lineHeight: "30px",
                                }}
                              >
                                5 Secrets Every Couple Should Know for a
                                Fulfilling Marriage
                              </Typography>
                            </Grid>
                            <Grid item lg={6} xs={12}>
                              <Typography
                                component="div"
                                sx={{
                                  color: "#66451c",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  lineHeight: "30px",
                                }}
                              >
                                The Importance of Family Values in Finding Your
                                Perfect Match
                              </Typography>
                            </Grid>
                            <Grid item lg={6} xs={12}>
                              <Typography
                                component="div"
                                sx={{
                                  color: "#66451c",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  lineHeight: "30px",
                                }}
                              >
                                The Impact of Divorce and Breakup on Mental
                                Health: What to Do Next
                              </Typography>
                            </Grid>
                            <Grid item lg={6} xs={12}>
                              <Typography
                                component="div"
                                sx={{
                                  color: "#66451c",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  lineHeight: "30px",
                                }}
                              >
                                5 Common Marriage Problems and Proven Solutions
                                for Lasting Harmony
                              </Typography>
                            </Grid>
                            <Grid item lg={6} xs={12}>
                              <Typography
                                component="div"
                                sx={{
                                  color: "#66451c",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  lineHeight: "30px",
                                }}
                              >
                                Are you made for each other? Explore with Indian
                                Astrology
                              </Typography>
                            </Grid>
                            <Grid item lg={6} xs={12}>
                              <Typography
                                component="div"
                                sx={{
                                  color: "#66451c",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  lineHeight: "30px",
                                }}
                              >
                                Finding Your Match: Exploring Compatibility
                                Through Chinese Astrology
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </MenuItem> */}
                      <Grid container spacing={2}>
                        {AllArticle.articles.map((item) => (
                          <Grid item lg={6} xs={12}>
                            <MenuItem
                              onClick={() => handleClose(`/article/${item.id}`)}
                              key={item.id}
                            >
                              <Typography
                                component="div"
                                sx={{
                                  color: "#66451c",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  lineHeight: "30px",
                                }}
                              >
                                {item.title}
                              </Typography>
                            </MenuItem>
                          </Grid>
                        ))}
                      </Grid>
                    </Menu>
                  </Typography>
                  <Typography>
                    <Button
                      className={Styles.menu_bar_link}
                      onClick={() => navigateTo("/faq")}
                    >
                      FAQ
                    </Button>
                  </Typography>
                </Typography>
              </Box>

              <Box sx={{ flexGrow: 0 }}>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </Typography>
    </>
  );
}
export default ResponsiveAppBar;
